import './index.scss'

import _ from 'lodash'

import Swiper from 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'

import 'particles.js'

/** banner **/
!(() => {
  let $paginationEl = $('.banner-pagination-wrapper')
  /** 用于创建 pagination 时确定名字 **/
  let textMap = _.map($paginationEl.find('p'), el => el.innerHTML)
  let swiper = new Swiper('.banner-swiper', {
    loop: true,
    speed: 1000,
    effect:'fade',
    slideActiveClass: 'active',
    autoplay: {
      delay: 2000,
      disableOnInteraction: false
    },
    watchSlidesProgress: true,
    on: {
      // 被背景设置 transition
      setTransition (speed) {
        for (var i = 0; i < this.slides.length; i++) {
          this.slides[ i ].querySelector('.banner-item').style.transition =
            speed + 'ms'
        }
      },
      // 根据 slideProgress 实现视差效果
      // progress () {
      //   for (let i = 0; i < this.slides.length; i++) {
      //     let slideProgress = this.slides[ i ].progress
      //     let innerOffset = this.width * 0.5
      //     let innerTranslate = slideProgress * innerOffset
      //     this.slides[ i ]
      //       .querySelector('.banner-item')
      //       .style
      //       .transform = 'translate3d(' + innerTranslate + 'px, 0, 0)'
      //   }
      // }
    },
    pagination: {
      el: $paginationEl,
      clickable: true,
      bulletClass: 'asdlkklzxc',
      bulletActiveClass: 'active',
      modifierClass: 'asdkzixclwww',
      renderBullet: function (index, className) {
        return `
            <div class="banner-pagination-item ${ className }">
              <div class="comp-padding-left">
                ${ textMap[ index ] }
              </div>
            </div>
        `
      }
    }
  })
})()

/** field **/
!(() => {
  let swiper = new Swiper('.field-body-swiper', {
    slidesPerView: 'auto',
    spaceBetween: 20,
    breakpoints: {
      0: {
        slidesPerGroup: 1
      },
      768: {
        slidesPerGroup: 4
      }
    },
    pagination: {
      el: '.field-pagination',
      bulletClass: 'asdwlidlzzzzz',
      bulletActiveClass: 'active',
      modifierClass: 'as8d7w8dz5x4cd',
      clickable: true,
      renderBullet: function (index, className) {
        return `<i class="${ className }"></i>`
      }
    }
  })
})()

/** 特点 **/
!(() => {
  particlesJS('tedian-canvas', {
    particles: {
      number: {
        value: 150
      },
      color: {
        value: '#adcbe5'
      },
      size: {
        value: 4,
        random: true
      },
      line_linked: {
        color: '#80b5e5'
      }
    }
  })
})()